<script lang="ts" setup>import { toRef as _toRef } from 'vue';

import { maska as vMask } from 'maska'
import { useFieldWithErrors } from '@consumer/composables/fieldWithErrors'
import { uniqueId } from '@consumer/helpers/string'
import { Dropdown, type Placement } from 'floating-vue'
import { useValidation } from '@consumer/composables/validation'
import type { IconDefinition } from '@fortawesome/fontawesome-common-types'

defineOptions({
  inheritAttrs: false,
})

withDefaults(defineProps<{
  type?: string
  id?: string
  modelValue?: string | number | null
  value?: any | null
  multiline?: boolean
  autoresize?: boolean // Only applicable with multiline: true
  label?: string
  hint?: string
  placeholder?: string
  placeholderAlignment?: 'left' | 'center' | 'right'
  icon?: string | IconDefinition
  iconRight?: string | IconDefinition
  hasError?: boolean
  error?: any
  errorTooltip?: boolean | Boolean | Placement | undefined
  mask?: string
  size?: 'small' | 'large'
  inputSize?: number
  wrapperClass?: any
  validations?: any

  // Provided automatically by GInput.
  hasErrors?: boolean
}>(), { type: 'text',multiline: false,placeholderAlignment: 'left',hasError: false,errorTooltip: false,size: 'large', })

const validator = useValidation(
  { value: __props.validations ?? {} },
  { value: computed(() => __props.modelValue ?? __props.value) } as any,
)

const inputId = computed(() => __props.id ?? uniqueId('g-input-'))

const emit = defineEmits<{
  'update:modelValue': [value: string]
  'update:activated': [value: boolean]
  focus: [value: FocusEvent]
  blur: [value: FocusEvent]
  input: [value: InputEvent]
  keydown: [value: KeyboardEvent]
  keyup: [value: KeyboardEvent]
  paste: [value: ClipboardEvent]
}>()

const inputElement = ref<HTMLInputElement | HTMLTextAreaElement>()

const __$temp_1 = (useFieldWithErrors(
  inputElement,
  () => __props.error ?? validator.value.$errors,
  () => __props.errorTooltip,
  () => __props.hasError,
)),
  activate = _toRef(__$temp_1, 'activate'),
  showError = _toRef(__$temp_1, 'showError'),
  errorMessages = _toRef(__$temp_1, 'errorMessages'),
  errorTooltipPlacement = _toRef(__$temp_1, 'errorTooltipPlacement');

const slots = useSlots()

const hasAddonLeft = computed(() => Boolean(slots.addonLeft || __props.icon))

const hasAddonRight = computed(() => Boolean(
  slots.addonRight || __props.iconRight || (showError.value && !__props.multiline),
))

const onInput = (event: InputEvent) => {
  const inputEl = event.target as HTMLInputElement | HTMLTextAreaElement
  emit('update:modelValue', inputEl?.value)
  emit('input', event)
}

const onKeypress = (event: KeyboardEvent) => {
  activate.value()

  if (event.key === 'Enter' && !__props.multiline) {
    (event.target as HTMLInputElement).blur()
  }
}

defineExpose({
  inputElement,
  activate: activate.value,
  focus: () => inputElement.value?.focus(),
  blur: () => inputElement.value?.blur(),
})
</script>

<template>
  <fieldset class="group g-input-field relative" :class="wrapperClass">
    <div v-if="label" class="flex items-center gap-1.5 mb-2.5 ">
      <slot name="label">
        <GLabel :for="inputId">
          <span v-html="label"/>
        </GLabel>
      </slot>

      <span class="font-normal text-sm text-grey-500">
        <slot name="hint">
          <span v-html="hint"/>
        </slot>
      </span>
    </div>
    <div class="g-input-group relative">

      <!-- Optional left icon, either provide icon name as :icon prop or customize template #icon -->
      <div
        v-if="hasAddonLeft"
        class="
          absolute inset-y-0 flex items-center pointer-events-none
          left-0 pl-4 g-input-icon g-input-icon-left
        "
      >
        <slot name="addonLeft">
          <GIcon v-if="typeof icon === 'string'" :name="icon" color="grey-500"/>
          <V2Icon v-else-if="icon" :icon="icon" size="sm"/>
        </slot>
      </div>

      <!-- Input can be customized throught template #input -->
      <slot v-bind="{ type, modelValue, placeholder, mask, hasAddonLeft, hasAddonRight, inputId }">
        <component
          :is="multiline ? 'textarea' : 'input'"
          :id="inputId"
          ref="inputElement"
          v-mask="mask"
          v-bind="$attrs"
          class="g-input"
          :class="[
            hasAddonLeft && '!pl-10',
            hasAddonRight && '!pr-10',
            `g-input-${size}`,
            {
              multiline,
              autoresize: autoresize ?? multiline,
              invalid: hasErrors || showError || hasError,
              'g-input-text': type !== 'range',
            },
          ]"
          :type="type"
          :value="modelValue || value"
          :placeholder="placeholder"
          :size="inputSize"
          @input="onInput"
          @keypress="onKeypress"
          @keydown="emit('keydown', $event)"
          @keyup="emit('keyup', $event)"
          @focus="emit('focus', $event)"
          @blur="emit('blur', $event)"
          @paste="$emit('paste', $event)"
        />
      </slot>

      <Dropdown
        v-if="errorTooltip && showError"
        shown
        :triggers="[]"
        :autoHide="false"
        :flip="false"
        :placement="errorTooltipPlacement"
        popperClass="g-input-field-error-tooltip"
        :arrowPadding="8"
        :distance="8"
      >
        <template #popper>
          <ul class="p-4 px-6 text-red-base">
            <li v-for="errorMessage, i in errorMessages" :key="i">
              {{ errorMessage }}
            </li>
          </ul>
        </template>
      </Dropdown>

      <!-- Optional right icon, either provide icon name as :icon prop or customize template #iconRight-->
      <div
        v-if="hasAddonRight"
        class="
          absolute inset-y-0 flex items-center
          right-0 pr-4 g-input-icon g-input-icon-right
          text-grey-700
        "
      >
        <slot name="addonRight">
          <GIcon v-if="typeof iconRight === 'string'" :name="iconRight" color="grey-500"/>
          <V2Icon v-else-if="iconRight" :icon="iconRight" size="sm"/>
          <GIcon v-else name="warning" color="red-base"/>
        </slot>
      </div>
    </div>

    <slot v-if="showError && !errorTooltip" name="errorMessage">
      <GInputError :error="errorMessages"/>
    </slot>
  </fieldset>
</template>

<style lang="scss">
.g-input-field .svg-inline--fa {
  margin-top: -0.125em;
}

.g-input {
  @apply w-full block outline-none rounded-v2md;

  --br: theme("colors.grey.primary");

  border: 1px solid var(--br);
  font-weight: 500;

  &::placeholder {
    text-align: v-bind("placeholderAlignment");
  }
}

.g-input-text {
  @apply flex-1 p-5 py-4;
  @apply text-base text-navy placeholder-v2-tertiary;

  &:active,
  &:hover,
  &:focus {
    @apply z-10;

    --br: var(--theme-color-primary-base);
  }

  &:focus {
    box-shadow: 0 0 0 1px var(--br);
  }

  &::placeholder {
    @apply text-grey-500;
  }

  &.g-input-small {
    @apply text-sm rounded-v2sm;

    padding: 0.5rem 0.75rem;
  }
}

.g-input.invalid {
  --br: theme("colors.red.base");

  color: theme("colors.red.base");
}

.g-input.autoresize {
  // stylelint-disable-next-line property-no-unknown
  field-sizing: content;
  height: auto;
  min-height: 78px;
  resize: none;
}

.g-input-field-error-tooltip {
  .v-popper__inner {
    @apply max-w-md sm:mx-6 lg:mx-0;
  }
}
</style>
